var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-sidebar',{attrs:{"right":"","shadow":"","backdrop":"","no-header":"","sidebar-class":"sidebar__apartment","body-class":"sidebar__apartment-body","aria-labelledby":"sidebar-no-header-title","id":"apartment-express-view","backdrop-variant":_vm.variant},scopedSlots:_vm._u([{key:"default",fn:function({}){return [(_vm.hasApartment && !_vm.appLoading)?_c('section',[_c('div',{staticClass:"head d-flex justify-content-between align-items-center pdf-item"},[_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"close__button d-flex justify-content-center align-items-center",on:{"click":_vm.hideApartmentSidebar}},[_c('base-arrow-left-icon',{attrs:{"width":32,"height":32}})],1),_c('span',{staticClass:"section__title"},[_vm._v(" "+_vm._s(_vm.objectName)+", "+_vm._s(_vm.sidebarApartment.building.name)+" ")])])]),_c('div',{staticClass:"d-flex w-100 justify-content-end"},[_c('div',{staticClass:"d-flex",staticStyle:{"font-size":"12px"}},[(_vm.statusViewPms && _vm.isNUNEZ(_vm.sidebarApartment.status))?_c('span',{staticStyle:{"display":"inline-flex","height":"40px","padding":"0 20px","justify-content":"center","align-items":"center","gap":"6px","flex-shrink":"0","color":"white","border-radius":"28px","white-space":"nowrap"},style:({
              'background-color': _vm.sidebarApartment.status.color,
              color:
                _vm.sidebarApartment.status.color === '#ffffff'
                  ? '#000000'
                  : '#ffffff',
              'border-radius':
                _vm.statusViewPms && _vm.isNUNEZ(_vm.sidebarApartment.status)
                  ? '28px 0 0 28px'
                  : '28px',
            })},[_vm._v(" "+_vm._s(_vm.sidebarApartment.status.title[_vm.$i18n.locale])+" ")]):_vm._e(),_c('span',{staticClass:"apartment__status d-flex justify-content-center align-items-center",class:`status-${_vm.status}`,staticStyle:{"display":"inline-flex","height":"40px","padding":"0 20px","justify-content":"center","align-items":"center","gap":"6px","flex-shrink":"0","border-radius":"28px","white-space":"nowrap"},style:({
              'border-radius':
                _vm.statusViewPms && _vm.isNUNEZ(_vm.sidebarApartment.status)
                  ? '0 28px 28px 0'
                  : '28px',
            })},[_vm._v(" "+_vm._s(_vm.$t(`apartments.status.${_vm.status}`))+" ")])])]),_c('parking-information',{staticClass:"pdf-item",attrs:{"apartment":_vm.sidebarApartment},on:{"for-print":_vm.getCalc}}),_c('div',{staticClass:"action-block"},[_c('b-tooltip',{attrs:{"target":"learnMore","triggers":"hover"}},[_c('p',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.$t("more_info"))+" ")])]),(_vm.permission.order)?_c('base-button',{attrs:{"text":`${_vm.$t('apartments.list.confirm')}`,"design":"violet-gradient"},on:{"click":_vm.orderApartment}}):_vm._e(),(_vm.permission.continueOrder)?_c('base-button',{staticClass:"checkout__button violet-gradient",attrs:{"text":`${_vm.$t('continue_registration')}`},on:{"click":_vm.continueApartmentOrder}}):_vm._e(),(_vm.permission.contract)?_c('router-link',{attrs:{"to":{
            name: 'contracts-view',
            params: { id: _vm.sidebarApartment.order.id },
          }}},[_c('base-button',{attrs:{"design":"violet-gradient px-5","text":`${_vm.$t('apartments.list.contract')}`}})],1):_vm._e()],1)],1):_vm._e(),(_vm.showReservationModal)?_c('reserve',{attrs:{"apartment":_vm.sidebarApartment.uuid},on:{"CreateReserve":_vm.updateContent}}):_vm._e(),(_vm.appLoading)?_c('base-loading',{staticClass:"h-100"}):_vm._e()]}}]),model:{value:(_vm.visibleComp),callback:function ($$v) {_vm.visibleComp=$$v},expression:"visibleComp"}})
}
var staticRenderFns = []

export { render, staticRenderFns }