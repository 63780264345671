export default {
  common: {
    apartment: "Xonadon",
    phone: "Телефон:",
    month: {
      january: "Yanvar",
      february: "Fevral",
      march: "Mart",
      april: "Aprel",
      may: "May",
      june: "Iyun",
      july: "Iyul",
      august: "Avgust",
      september: "Sentyabr",
      october: "Oktyabr",
      november: "Noyabr",
      december: "Dekabr",
    },
  },
  bot: {
    title: "Бот",
    pages: "Страницы",
    objects: "Объекты",
  },
  pdf: {
    manager: "Менеджер по продажам",
    creation_date: "Дата создания информационного листа",
    variants: "Варианты оплаты и система скидок*",
    table: {
      variants: "Вариант оплаты",
      m2_price: "Цена продажи за квадрат",
      full_price: "Общая цена",
      first: "Первый платеж",
      discount_percent: "Скидка в процентах",
      discount_amount: "Общая скидка",
      discounted_price: "Цена со скидкой",
      variant_value: "{val}% предоплаты",
    },
    detail: {
      title_type: "Тип оплаты",
      title_value: "Сумма оплаты",
      first: "Первоначальная",
      monthly: "Ежемесячная",
      last: "Дата последнего платежа",
    },
    monthly_title:
      "График оплаты (количество месяцев: {month}) с {prepay}% предоплатой",
    disclaimer1:
      "*Вся информация действительна на дату создания информационного листа.",
    disclaimer2:
      "Перед заключение договора, просим уточнять всю информацию в офисах продаж",
  },
  contract_is_expired: "Просроченные договоры",
  contract_is_duplicate: "Дубликат договоры",
  languages: "Tillar",
  archiveV: "Arhivlash",
  unarchiveV: "Arhivdan olish",
  registration_of_parking_lots: "Avtoturargohlarni ro'yxatdan o'tkazish",
  parking: "Avtoturargoh",
  parkings: "Avtoturargohlar",
  export: "Eksport",
  initial_payment_date: "Dastlabki to'lov sanasi",
  monthly_payment_date: "Oylik to'lov sanasi",
  the_creator: "Yaratuvchi",
  blocks_of_the_object: "{objectName} bloklari",
  document_type: "Hujjat turi",
  checkout: {
    address_line: "Doimiy turar mazili",
  },
  filter: {
    by_role: "Rol bo'yicha filtrlash",
  },
  reports: {
    title: "Hisobot",
    not_found: "Hisobotlar topilmadi",
  },
  select_type: "Turni tanlang",
  report: {
    title: "Hisobot",
    list: "Hisobotlar ro'yxati",
    status: "Status",
    clients: "Mijozlar",
    orders: "Buyurtmalar",
    filter_by: "Bo'yicha filtrlang",
    select_report_type: "Hisobot turini tanlang",
    statuses: {
      created: "yaratildi",
      processing: "jarayonda",
      successful: "muvaffaqiyatli",
      failed: "muvaffaqiyatsiz",
      cancelled: "bekor qilingan",
    },
  },
  from_the_date_of: "Sanadan boshlab",
  to_the_date_of: "Sanasigacha",
  download_report: "Yuklab olish",
  checkout_permission_error_when_initial_set_to_zero:
    "Boshlang'ich to'lovni 0 so'm qilish imkoniyati mavjud emas",
  checkout_warning_when_initial_set_to_zero:
    "Boshlang'ich to'lovni 0 so'm qildingiz",
  deleted_date: "Oʻchirilgan sana",
  contracts_not_found: {
    title: "Shartnomalar topilmadi",
    description: "Boshqa qidiruv maʼlumotlarini kiritib koʻring",
  },
  restore_contract: "Shartnomani tiklash",
  contract_archive_title: "Shartnomani arxivlash",
  in_the_archive: "Arxivda",
  move_to_archive: "Arxivga o'tkazmoq",
  remove_from_archive: "Arxivdan chiqarmoq",
  deselect: "Tanlovni olib tashlang",
  color: "Rang",
  statuses: {
    title: "Statuslar",
    add: "Status qo'shish",
    pick_color_for_status: "Status uchun rangni tanlang",
    select: "Statusni tanlang",
    singular: "Status",
    change: "Statusni o'zgartirish",
  },
  holders: {
    title: "Egalari",
    add: "Ega qo'shish",
    singular: "Ega",
    select: "Egasini tanlang",
    change: "Egasini o'zgartirish",
  },
  home: {
    title: "Asosiy",
  },
  company_type: "Kompaniya turi",
  list_of_agreements: "Shartnomalar ro'yxati",
  edit_apartment: "Xonadonni tahrirlash",
  edit_parking: "Avtoturargohni tahrirlash",
  changes_successfully_saved: "Изменения успешно сохранены.",
  yes_cancel: "Xa, bekor qilish",
  checkout_warning_before_cancel:
    "Rasmiylashtirishni bekor qilishni xohlashingizga ishonchingiz komilmi?",
  this_action_cannot_be_undone: "Ushbu harakatni bekor qilib bo'lmaydi.",
  to_all_contracts: "Barcha shartnomalarga",
  download_all_contract: "Barcha shartnomalarni yuklab olish",
  contract_completed_successfully: "Shartnoma muvaffaqiyatli yakunlandi",
  completion_of_registration: "Ro'yxatdan o'tishni yakunlash",
  commentary: "Izoh",
  comment_required_to_complete: "Jarayonni yakunlash uchun izoh talab qilinadi",
  country: "Mamlakat",
  country_code: "Mamlakat kodi",
  priority_countries: "Ustuvor davlatlar",
  add_country: "Mamlakat qo'shish",
  placeholder_uz: "O'zbek tilida",
  placeholder_ru: "Rus tilida",
  placeholder_eng: "Ingliz tilida",
  has_the_powers_of_vip: "V.I.P vakolatlariga ega.",
  add_type: "Tur qo'shish",
  normal_client: "Oddiy mijoz",
  actions: "Amallar",
  title: "Nom",
  client_types: "Mijoz turlari",
  return_all_apartments: "Barcha xonadonlarni qaytarish",
  cleaned_apartments: "O'chirilgan xonadonlar",
  final_total_price: "Yakuniy umumiy narx",
  fields_not_filled_out_or_incorrectly:
    "Qizil rang bilan belgilangan maydonlar to'ldirilmagan yoki noto'g'ri to'ldirilgan",
  fax: "Faks",
  legal_address: "Yuridik manzil",
  ndc: "NDS",
  account_number: "Hisob raqami",
  oked: "ОКЭД",
  bank: "Bank",
  company_name: "Firma nomi",
  client_information: "Mijoz haqida ma'lumot",
  payment_date: "To'lov sanasi",
  first_payment_date: "Birinchi to'lov sanasi",
  payment_details_2: "To'lov tafsilotlari",
  area: "Maydon",
  number_of_blocks: "Blokning qavatlar soni",
  characteristics_of_the_apartment: "Kvartiraning xususiyatlari",
  characteristics_of_the_parking: "Avtoturargoh xususiyatlari",
  add_another_number: "Boshqa raqam qo'shish",
  email: "Elektron pochta",
  additional_email: "Qo'shimcha elektron pochta",
  additional_phone_number: "Qo'shimcha telefon raqami",
  client_contact_details: "Mijoz bilan bog'lanish ma'lumotlari",
  communication_language: "Muloqot tili",
  place_of_issue__of_passport_id: "Pasport yoki ID berilgan joy",
  passport_series_example:
    "Pasport yoki ID raqami va seriyasi (misol. AB1234567)",
  fio_full: "Familiya, otasining ismi yoki boshqa ismi",
  nation: "Millat",
  person_type: "Subyekt",
  physical_person: "Jismoniy shaxs",
  legal_entity: "Yuridik shaxs",
  preview: "Ko'rib chiqish",
  next_apartment: "Keyingi kvartira",
  clients_personal_data: "Mijozning shaxsiy ma'lumotlari",
  please_select: "Пожалуйста выберите",
  type: "Turi",
  total: "Umumiy",
  additional: `Qo'shimcha`,
  passport_series: "Pasport raqami",
  name: "Ism",
  second_name: "Otasining ismi",
  last_name: "Familiya",
  cyrillic_shortcut: "kir",
  latin_shortcut: "lot",
  passport_issue_date: "Passport berilgan sana",
  for_example: "masalan",
  remainder: "Qoldiq",
  apartment_detail: "Kvartira tafsiloti",
  parking_detail: "Avtoturargoh tafsilotlari",
  apartment_details: "Kvartira tafsilotlari",
  payment_details: `To'lov tafsilotlari`,
  price_m: "Narx M",
  price_m2: "M2 uchun narx",
  other_price: "Boshqa narx",
  remove_apartment: `Kvartirani o'chirish`,
  component: "komponent",
  import: "Import",
  found: "Topildi",
  not_found: "Topilmadi",
  pin_menu: "Menuni ko'rsatish",
  hide_menu: "Menuni yashirish",
  roles_permission: {
    activate_all: "Hammasini faollashtirish",
    placeholder_uz: "O'zbek tilida",
    placeholder_ru: "Rus tilida",
    slug: "Guruh nomi prefiksi",
    view: "Право на просмотр",
    create: "Право на создание",
    update: "Право на редактирование",
    delete: "Право на удаление",
    titles: {
      general: "Umumiy",
      objects: "Obyektal",
      promos: "Chegirmalar",
      layouts: "Xonadon Rejalari",
      apartments: "Xonadonlar",
      execution: "Kelishuvlar",
      contracts: "Shartnomalar",
      users: "Foydalanuvchilar",
      roles: "Rollar",
      debtors: "Qarzdorlar",
      companies: "Kompaniyalar",
      payment_account: "Hisob",
      branches: "Filiallar",
      release_note: "Reliz qaydlar",
      settings: "Sozlamalar",
    },
    settings: {
      view: "Sozlamalar sahifasini ko'rish huquqi",
      apartments: {
        holder: {
          view: "Kvartiraning egasi kimligini ko'rish huquqi",
          edit: "Kvartiraning egasini tahrirlash huquqi",
        },
        status: {
          view: "Kvartiraning statusini ko'rish huquqi",
          edit: "Kvartiraning statusini tahrirlash huquqi",
        },
      },
      holders: {
        create: "Ega yaratish huquqi",
        update: "Ega tahrirlash huquqi",
        delete: "Ega o'chirish huquqi",
        view: "Egalar sahifasini ko'rish huquqi",
      },
      statuses: {
        create: "Status yaratish huquqi",
        update: "Status tahrir qilish huquqi",
        delete: "Status o'chirish huquqi",
        view: "Statuslar sahifasini ko'rish huquqi",
      },
      client_types: {
        create: "Mijoz turlarini yaratish huquqi",
        update: "Mijoz turlarini tahrirlash huquqi",
        delete: "Mijoz turlarini o'chirish huquqi",
        view: "Mijoz turlari sahifasini ko'rish huquqi",
      },
      client_countries: {
        create: "Mijoz mamlakatlarini yaratish huquqi",
        update: "Mijoz mamlakatlarini tahrir qilish huquqi",
        delete: "Mijozning mamlakatlarini o'chirish huquqi",
        view: "Mijoz mamlakatlari sahifasini ko'rish huquqi",
      },
      contracts: {
        main_manager: "Bosh menejer",
      },
    },
    general: {
      role_name_ru: "Rol (RU)",
      role_name_uz: "Rol (UZ)",
      exchange_rates: "Valyuta kursi",
      theme: "Interfeys (tungi, kunduzgi)",
      language: "Til (rus, o'zbek)",
      profile_settings: "Profil sozlamalari",
      user_data: "Foydalanuvchi haqidagi ma'lumotlarni o'zgartirish huquqi",
      user_password: "Foydalanuvchi parolini o'zgartirish huquqi",
    },
    objects: {
      watch_objects: "Obyektlar sahifasini ko'rish huquqi",
      create_object: "Obyekt yaratish huquqi",
      edit_object: "Obyektlarni tahrirlash huquqi",
      show_object: "Bitta obyektni ko'rish huquqi",
      delete_object: "Obyekt o'chirish huquqi",
      download_logo: "Logotip yuklash huquqi",
    },
    promos: {
      watch_promos_page: "Chegirmalar sahifasini ko'rish huquqi",
      create_promo: "Chegirma yaratish huquqi",
      edit_promo: "Chegirmani tahrirlash huquqi",
      delete_promo: "Chegirma o'chirish huquqi",
    },
    layouts: {
      watch_layouts: "Xonadon rejasi sahifasini ko'rish huquqi",
      create_layouts: "Xonadon rejasi yaratish huquqi",
      edit_layouts: "Xonadon rejasi tahrirlash huquqi",
      delete_layouts: "Xonadon rejasi o'chirish huquqi",
    },
    apartments: {
      watch_apartments:
        "Xonadonlar sahifasini ko'rish huquqi (bitta obyekt sahifasi)",
      filter_apartments: "Sahifadagi xonadonlar ro'yxatini filtrlash huquqi",
      edit_apartment: "Xonadonni tahrirlash huquqi (bitta obyekt sahifasi)",
      status_apartment:
        "Xonadonni sotuvdan olish va qo'yish huquqi (bitta obyekt sahifasi)",
      change_list_apartments: "Xonadonlar jadvalini ko'rish huquqi",
      list_apartments1:
        "Xonadonlar ro'yxatini o'zgartirish uchun shaxmatka 1.0 dan foydalanish huquqi",
      list_apartments2:
        "Xonadonlar ro'yxatini o'zgartirish uchun shaxmatka 2.0 dan foydalanish huquqi",
      using_layouts:
        "Xonadonlar ro'yxatini o'zgartirish uchun xonadon rejasidan foydalanish huquqi",
      read_comments: "Izohlarni ko'rish huquqi",
      create_comments: "Izohni yaratish huquqi",
      update_comments: "Izohni tahrirlash huquqi",
      delete_comments: "Izohni o'chirish huquqi",
    },
    execution: {
      book_apartment: "Xonadonni bron qilish huquqi (bitta obyekt sahifasi)",
      decorate_apartment: "Xonadonni ro'xatdan o'tkazish huquqi",
      tag_acquaintances: "Do'stlarni belgilash huquqi",
      change_payment: "Boshqa narxda shartnoma tuzish huquqi",
      edit_date: "Xonadonni ro'xatdan o'tkazilish sanasini o'zgartirish huquqi",
      change_monthly_payment: "Boshqa oylik to'lovda shartnoma tuzish huquqi",
      full_access: "Barchasiga ruxsat berish",
    },
    contracts: {
      list_contract: "Shartnomalar ro'yxati",
      watch_deals: "Shartnomalar ro'yxatini ko'rish huquqi",
      filter_deals: "Shartnomalar ro'yxatini filtrlash huquqi",
      watch_deal: "Bitta shartnomani ko'rish huquqi",
      download_deal: "Shartnomalarni yuklash huquqi",
      decline_deal: "Shartnomani bekor qilish huquqi",
      client_type:
        "Mijoz turini o'zgartirish huquqi ('Mijoz haqida batafsil' qismida)",
      watch_branch_deals:
        "Filialning barcha shartnomalarini ko'rish huquqi (filial ish yurituvchisi)",
      import: "To'lovlarni yuklab olish huquqi",
      executor: "Ijro etuvchi",
      list: "To'lovlarni ko'rish huquqi",
      change_date_deal: "Shartnoma sanasini o'zgartirishga ruxsat berish",
      edit_monthly_payment: "Oylik toʻlovlarni tahrirlashga ruxsat berish",
      edit_payment: "To'lovni tahrirlash huquqi (bitta shartnoma sahifasi)",
      delete_payment: "To'lovni o'chirish huquqi (bitta shartnoma sahifasi)",
      add_payment: "To'lovni qo'shish huquqi (bitta shartnoma sahifasi)",
      create_type_payment: "Dastlabki to'lov turini yaratish huquqi",
      edit_type_payment: "Dastlabki to'lov turini tahrirlash huquqi",
      delete_type_payment: "Dastlabki to'lov turini o'chirish huquqi",
      create_monthly_payment: "Dastlabki to'lov turini yaratish huquqi",
      edit_monthly_type: "Oylik to'lov turini tahrirlash huquqi",
      delete_monthly_type: "Oylik to'lov turini o'chirish huquqi",
      reissue_view: "Qayta tashkillashtirishni ko'rish huquqi",
      reissue_create: "Qayta tashkillashtirish yaratish huquqi",
      uniformity: "Mutanosiblikni ko'rish huquqi",
      comments: "Shartnomalarga izoh yozish huquqi",
      edit: "Shartnomalarni tahrirlash huquqi",
      full_access: "Barchasiga ruxsat berish",
      read_comments: "Izohlarni ko'rish huquqi",
      create_comments: "Izohni yaratish huquqi",
      update_comments: "Izohni tahrirlash huquqi",
      delete_comments: "Izohni o'chirish huquqi",
      download_order: "Buyurtmalar ro'yxatini yuklash huquqi",
      download_report: "Hisobotlar ro'yxatini yuklash huquqi",
    },
    users: {
      watch_users: "Foydalanuvchilarni ko'rish huquqi",
      create_user: "Foydalanuvchilarni yaratish huquqi",
      edit_user: "Foydalanuchilarni tahrirlash huquqi",
      delete_user: "Foydalanuvchilarni o'chirish huquqi",
    },
    release_note: {
      watch_notes: "Reliz qaydlarini ko'rish huquqi",
      create_note: "Reliz qaydini yaratish huquqi",
      edit_note: "Reliz qaydini tahrirlash huquqi",
      delete_note: "Reliz qaydini o'chirish huquqi",
    },
    roles: {
      watch_roles: "Rollar ro'xatini ko'rish huquqi",
      add_roles: "Rollar qo'shish huquqi",
      edit_roles: "Rollarni tahrirlash huquqi",
      delete_roles: "Rollarni o'chirish huquqi",
    },
    debtors: {
      watch_debtors: "Qarzdorlar ro'yxatini ko'rish huquqi",
    },
    companies: {
      watch_companies: "Kompaniyalar ro'yxatini ko'rish huquqi",
      watch_company: "Bitta kompaniyani ko'rish huquqi",
      create_company: "Kompaniya yaratish huquqi",
      edit_companies: "Kompaniyani tahrirlash huquqi",
      delete_company: "Kompaniyani o'chirish huquqi",
    },
    payment_account: {
      access_payments_list: "Hisob raqamlar ro'yxatini ko'rish huquqi",
      add_payment: "Hisob raqamini qo'shish huquqi",
      edit_payment: "Hisob raqamini tahrirlash huquqi",
      delete_payment: "Hisob raqamini o'chirish huquqi",
    },
    branches: {
      watch_branches: "Filiallar ro'xatini ko'rish huquqi",
      edit_branch: "Filialni tahrirlash huquqi",
      delete_branch: "Filialni o'chirish huquqi",
      add_branch: "Filial qo'shish huquqi",
      watch_contract_template: "Shartnomani na'muna shablonini ko'rish huquqi",
      create_contract_template:
        "Shartnomani na'muna shablonini yaratish huquqi",
      delete_contract_template:
        "Shartnomani na'muna shablonini o'chirish huquqi",
      make_main_branch_template:
        "Asosiy filial shartnomani na'munasini yaratish huquqi",
      template_download: "Shartnomani na'muna shablonini yuklash huquqi",
      template_instruction:
        "Shartnomani na'muna shablonini ko'rsatmasini ko'rish huquqi",
    },
    reports: {
      create: "Право создавать отчеты",
      view: "Hisobotlarni yaratish huquqi",
    },
  },
  user: {
    first_name: "Ism",
    last_name: "Familiya",
    second_name: "Otasining ismi",
    phone: "Telefon raqami",
    password: "Parol",
    email: "E-mail",
    role: "Rol",
    phone_number: "Telefon raqami",
    validation_last_password: "Eski parol noto’g’ri terilgan!",
    validation_password:
      "Parol kamida 8 ta harf yoki raqamlardan iborat bo’lishi kerak!",
    validation_confirm_password:
      "Tasdiqlash paroli yangi parol bilan bir xil bo’lishi kerak",
  },
  xonsaroy: "Xonsaroy",
  checkout_booking: "Rasmiylashtirish",
  apartment_make_contract: "Avtoturargohni rasmiylashtirish",
  parking_make_contract: "Оформление парковки",
  apartment: "Xonadon",
  installment: "Bo'lib to'lash rejasi",
  prepayment: "Oldindan to'lov",
  discount_per_m: "Chegirma M",
  discount_per_m2: "M2 uchun chegirma",
  enter_discount: "To'lov turini tanlang",
  address_office:
    "Toshkent shahri, Yunusobod tumani, Yangi shahar ko‘chasi, 64A-uy",
  motto: "Orzular sari ishonchli qadam!",
  quarter: "chorak",
  of_the_year: "yil",
  thank_you: "Rahmat",
  chosen: "Tanlandi",
  no_cancel: "Yo'q, bekor qiling",
  yes_delete: "Ha, o'chirilsin",
  remove_payment: "To'lov o'chirilsinmi ?",
  deleted: "O'chirildi",
  successfully: "Muvaffaqiyatli!",
  payment_change: "To'lov tahrirlandi",
  other: "Boshqa",
  booked: "Band qilingan",
  not_for_sale: "Sotilmaydi",
  remove_from_sale: "Sotuvdan olib tashlash",
  return_to_sale: "Sotuvga qaytarish",
  edit: "Tahrirlash",
  edited: "tahrirlandi",
  upload_logo: "Logotipni yuklash",
  loading: "Iltimos kuting...",
  recheck: "Tekshirish ",
  add: "Qo'shish",
  delete: "O'chirish",
  undelete: "Восстановить",
  action: "Xarakat",
  search: "Qidirish",
  save: "Saqlash",
  create: "Yaratish",
  view: "Ko'rish",
  cancel: "Bekor qilish",
  back: "Ortga",
  next: "Davom etish",
  create_agree: "Tasdiqlash",
  create_agree_apartments: "Xonadonlarni ro'yxatdan o'tkazish",
  save_changes: "O'zgarishlarni saqlash",
  reason_recontract: "Qayta rasmiylashtirish sababi",
  number_agree: "Shartnoma raqami",
  create_date: "Tuzilgan sana",
  re_contract: "Qayta rasmiylashtirish",
  recontract_details: "Qayta rasmiylashtirish tafsilotlari",
  contract_log: "Faoliyat jurnali",
  recontract_download: "Qayta rasmiylashtirilgan shartnomani yuklash",
  leave_import: "Importni tark etasizmi?",
  leave_import_text:
    "Haqiqatan ham importni bekor qilish va undan chiqishni xohlaysizmi? </br>" +
    "Barcha qo'llaniladigan o'zgarishlar bekor qilinadi.",
  no_leave: "Yo'q, qolaman",
  yes_leave: "Ha, chiqaman",
  yes: "Ha",
  no: "Yo'q",
  id: "Id",
  image: "Surat",
  address: "Manzil", // 'settings': 'Настройки',
  no_data: "Ma'lumotlar yo'q",
  logout: "Chiqish",
  list: "Ro'yxat",
  close: "Yopish",
  ye: "so`m",
  usd: "$",
  _usd: "USD",
  uzs: "UZS",
  price_from: `{msg} so'm dan`,
  price_from_m2: `{msg} so'm/m<sup>2</sup> dan`,
  price_sold_m2: "{msg} uchun sotish narxi:",
  total_price: "Umumiy narx",
  general: "Umumiy",
  edit_password: "Parolni o'zgartirish",
  profile: "Profil",
  category: "Kategoriya",
  full: "To'liqligicha",
  monthly: "Oylik",
  month: "Oy",
  month_lowercase: "oy",
  months: "Oylar",
  months_to: "oygacha",
  table_months_payment: "Oylik to'lovlar jadvali",
  duration: "Davomiyligi",
  more_info: "Batafsil ma'lumot",
  payment_type: "To'lov usuli",
  created_at: "Yaratilgan vaqti",
  reservation: "Band qilish",
  free_of_charge: "Boshlang'ich to'lovsiz",
  without_initial: "Boshlang'ich to'lovsiz",
  go_back: "Ortga",
  activate: "Faollashtirish",
  deactivate: "To'xtatish",
  monthly_payment: "Oylik to'lov",
  sum_text: "So'm",
  sum: "So'm",
  usd_text: "Usd",
  text: "Matn",
  apartments_count: "Xonadonlar soni",
  apartment_list: "Xonadonlar ro'yxati",
  contract_file_not_found: "Kontrakt fayl topilmadi",
  instruction: "Ko'rsatma",
  validation_warning_to_bottom:
    "Maydonlardan biri noto'g'ri to'ldirilgan, barcha maydonlarni yana tekshiring!",
  new_name: "Yangi nom",
  meaning: "Manosi",
  example_by_2: "Misol",
  payment_schedule: "To'lov jadvali",
  object_details: "Obyekt tafsilotlari",
  client_details: "Foydalanuvchi malumotlari",
  client_info: "Mijoz haqida ma'lumot",
  contract_details: "Shartnoma tafsilotlari",
  comment: "Izoh",
  initial_payment: "Boshlang'ich to'lov",
  monthly_pay: "Oylik to'lov",
  floor: "Qavat",
  paid: "To'langan",
  waiting_to_payment: "Kutilmoqda",
  partially: "Qisman to'langan",
  cash: "Naqd",
  error: "Xatolik!",
  view_map: "Xaritada ko'rsatish",
  object_map: "Obyekt joylashuvi",
  object_create: "Obyekt yaratish",
  main: "Asosiy",
  passport: "Pasport ma'lumotlar",
  builder: "Quruvchi",
  type_payment: "To'lov turlari",
  details: "Tafsilotlar",
  other_details: "Boshqa tafsilotlar",
  phone: "Telefon",
  other_phone: "Qo'shimcha telefon raqami",
  extra: "Qo'shimcha",
  client_type: "Mijoz turi",
  birth_day: "Tug'ilgan sana",
  series: "Seriya",
  given: "Tomonidan berilgan",
  given_date: "Berilgan sana",
  company: "Kompaniya",
  agent: "Vakil",
  pc: "R/S",
  inn: "INN",
  mfo: "MFO",
  number: "Telefon",
  contract_number: "Shartnoma raqami",
  contract_price: "Shartnoma summasi",
  date: "Sana",
  formed: "Rasmiylashtirilgan",
  status: "Status",
  familiar: "Tanish",
  unfamiliar: "Notanish",
  continue_registration: "Rasmiylashtirishni davom etish",
  square_meter: "m<sub>2</sub>",
  from: "dan",
  to: "gacha",
  apartment_number: "Xonadon raqami",
  completion_date: "Tugallanish vaqti",
  plan_area: "Loyiha maydoni",
  balcony: "Balkon",
  number_of_rooms: "Xonalar soni",
  number_of_floors_of_the_block: "Blokning qavatlar soni",
  search_placeholder: "ФИО, телефон, номер договора",
  apartment_rooms: `{msg} ta xonadon topildi`,
  price_monthly: "{month} oy {price} so'm dan",
  total_discount: "Umumiy chegirma",
  total_discount_percent: "Umumiy chegirma %",
  starting_price: "Boshlang'ich narxi",
  characters: "Xususiyatlari",
  clear: "Tozalash",
  selling_price: "Sotuv narxi",
  payment_discount: "to'lov turi",
  by_price: "{price} so`mdan",
  by_price_m2: "{price} so`m/M.dan",
  selected_variant: "Tanlangan to'lov turi",
  other_variant: "Boshqa to'lov turlari",
  contract_number_or_full_name: "Shartnoma raqami yoki FIO",
  activity_type_full_name: "FIO yoki kiritilgan o'zgarish turi",
  base_price_from: "So`m (dan)",
  base_price_to: "So`m (gacha)",
  client: "Foydalanuvchi",
  debt_details: "Qarz tafsilotlari",
  debt: "Qarz",
  go_to_contract: "Shartnomaga o'tish",
  week: "Hafta",
  today: "Bugun",
  day: "Kun",
  refresh_password: "Parolni yangilash",
  manager: "Menejer",
  contract_regeneration: "Shartnomani qayta tiklash",
  lotin_compress: "(lotin.)",
  cyrill_compress: "(kirill.)",
  details_of_the_agreement: "Kelishuv tafsilotlari",
  date_of_the_agreement: "Kelishuv sanasi",
  reason_for_reissuing: "Qayta rasmiylashtirish sababi",
  apply: "Qo'llash",
  agreement_number: "Shartnoma raqami",

  monday: "Dushanba",
  tuesday: "Seshanba",
  wednesday: "Chorshanba",
  thursday: "Payshanba",
  friday: "Juma",
  saturday: "Shanba",
  sunday: "Yakshanba",

  january: "Yanvar",
  february: "Fevral",
  march: "Mart",
  april: "Aprel",
  may: "May",
  june: "Iyun",
  july: "Iyul",
  august: "Avgust",
  september: "Sentyabr",
  october: "Oktyabr",
  november: "Noyabr",
  december: "Dekabr",

  weekDayAbbreviation: {
    0: "YK",
    1: "DSH",
    2: "SE",
    3: "CHR",
    4: "PY",
    5: "JM",
    6: "SHN",
  },

  more: "Yana",
  go_to_day: "Batafsil ko'rish",

  tab_status: {
    all: "Hammasi",
    available: "Mavjud",
    booked: "Band qilingan",
    sold: "Sotilgan",
    unavailable: "Mavjud emas",
    promo: "Aksiya",
    on_payment: "To'lovda",
    closed: "Yopilgan",
    reorder: "Qayta rasmiylashtirilgan",
    active: "Faol",
    de_active: "Faol emaas (deaktiv)",
    archived: "Arxiv",
    deleted: "O'chirilgan",
    is_expired: "expired",
  },

  promo: {
    name: "Aksiya",
    promos: "Aksiyalar",
    table_name: "Nomi",
    blocks: "Binolar",
    floors: "Qavatlar",
    creation_title: "Aksiya yaratish",
    modal_input_text_label: "Aksiya nomi",
    date_of_start_title: "Aksiyaning boshlanish kuni",
    date_of_end_title: "Aksiyaning tugash kuni",
    date_of_start_label: "Aksiya shu kundan boshlab amal qiladi",
    date_of_end_label: "Aksiya shu kungacha amal qiladi",
    select_block: "Bino (larni) tanlang",
    select_floor_plan:
      "Binodagi qavat, xonadon rejasi yoki xonadon uchun aksiya belgilang",
    select_price: "Aksiya narxi (m2 uchun)",
    select_type: "Aksiya turini tanlang",
    select_sum: "So'm",
    select_by_floor: "Qavatlar bo'yicha",
    select_by_apartment: "Xonadon bo'yicha",
    select_by_plan: "Xonadon rejasi bo'yicha",
    add_price: "Narx qo'shish",
    warn_before_delete: "Aksiyani oʻchirib tashlamoqchimisiz?",
    successfully_deleted: "Aksiya muvaffaqiyatli o'chirildi",
    successfully_created: "Aksiya muvaffaqiyatli yaratildi",
    successfully_edited: "Aksiya yangilandi",
    edit_title: "Aksiyani tahrirlash",
    alert_before_close_title: "Rostdan ham yopmoqchimisiz ?",
    alert_before_close_no: "Yo'q",
    by_promo: "Aksiya bo'yicha",
    in_promo: "Aktsiyalarda",
    promo_rate: "Tarif",
    initial_price: "Boshlang'ich to'lov (%)",
  },

  auth: {
    email: "E-mail",
    password: "Parol",
    login: "Kirish",
    validation_email: "Elektron pochta xato formatda kiritildi",
    validation_password: "Parol 8ta belgilardan iborat bolishi kerak",
  },

  messages: {
    created: "Muvaffaqiyatli kiritildi!",
  },

  notifications: {
    title: "Bildirishnomalar",
  },

  payments: {
    payment_total: "Jami to'langan",
    payment: "Shartnoma summasi",
    course: "Kurs",
    balance: "Qoldiq",
    initial_fee: "Boshlang'ich to'lov",
    initial_fee_remains: "To'langan boshlang'ich to'lov",
    installment: "Bo'lib to'lash rejasi",
    month: "oy",
    course_name: "so'm",
    contract: "Shartnoma",
    payment_list: "To'lovlar ro'yxati",
    payment_download: "To'lovlarni import qilish",
    payment_add: "To'lov qo'shish",
    import:
      "Toʻlovlarni import qilish uchun faylni .xls yoki .csv formatida yuklang",
    drag_file: "Faylni ushbu maydonga torting yoki",
    click_file: "faylni tanlash uchun bosing",
    download_template: "Shablonni yuklab olish",
    table: {
      date: "Sana",
      balance: "Miqdor",
      type: "Tur",
      method: "Usul",
      comment: "Izoh",
      actions: "Harakatlar",
    },
  },

  contracts: {
    list_contracts: "Shartnomalar ro'yxati",
    name: "Shartnomalar",
    reset_filter: "Filterni tozalash",
    apply_filter: "Qidirish",
    object_name: "Obyektning nomi",
    client_type: "Mijoz turi",
    date_type: "Harakat turi",
    apartment_number: "Xonadon raqami",
    agreement_date: "Kelishuv sanasi",
    filter_title: "Qidiruv filtrlari",
    transfer: "O'tkazilgan",
    warning: "Ogohlantirish",
    edit_payment: "To'lovni tahrirlash",
    warn_before_delete_contract:
      "Shartnomani arxivlamoqchimisiz? Bu amalni ortga qaytarib bo‘lmaydi.",
    comment: "Izoh",
    comment_delete_contract: "O'chirish sababini ko'rsating",
    add_payment_successfully: "To'lovlar ro'yxatiga muvaffaqiyatli qo'shildi",
    deleted_payment_successfully:
      "Toʻlovni oʻchirib tashlamoqchimisiz? Bu amalni ortga qaytarib bo‘lmaydi.",
    warn_before_delete_payment:
      "Toʻlovni oʻchirib tashlamoqchimisiz? Bu amalni ortga qaytarib bo‘lmaydi.",
    warning_in_payment:
      "Boshlang'ich to'lov uchun {price} so'm ortiqcha qolgan. Shu miqdorni oylik to'lovga o'tkazishni xohlaysizmi?",
    title: "Shartnomalar",
    download: "Shartnomani yuklab olish",
    f_i_o: "F.I.SH", // asd
    filter_with_date: "Sana bo'yicha filtrlash", // asd
    client_name: "Foydalanuvchi ismi", // asd
    client_phone: "Telefon raqami", // asd
    price: "Narxi", // asd
    object: "Ob'ekt", // asd
    show_by: "Ko'rsatish",
    search: "Ism, telefon raqami, shartnoma raqami",
    filter: "Qidiruv filtrlari",
    add_note: "Eslatma qo'shish",
    note: "Eslatma",
    notes: "eslatmalar",
    no_notes: "Eslatmalar yoq",
    no_note: "Hozircha hech qanday eslatma yo'q",
    delete_note: "Eslatmani o'chirish",
    note_delete_confirmation:
      "Eslatmani o'chirishga qaroringiz qattiymi?\n" +
      "Quydagi buyruqni bekor qilishni ilojisi yo'q.",
    edit_note: "Eslatmani tahrirlash",
    note_text: "Eslatma matni",
    individual: "individual",
    activity_log: {
      reissue: "Qayta tashkillashtirish o'zgarishlar kiritlgan",
      comments: "Izohlarda o'zgarish kiritilgan",
      payments_histories: "To'lovlar tarixida ozgarish kiritilgan",
      activity_type: "O'zgarish turi",
      attached_files: "Biriktirilgan fayllar",
      actions: {
        deleted: "O'chirilgan",
        reissue: "Qayta tashkillashtirilgan",
        created: "Yaratilgan",
        edited: "Tahrirlangan",
        updated: "Tahrirlangan",
      },
    },
    table: {
      contract: "Shartnoma",
      client: "Foydalanuvchi",
      phone_number: "Telefon",
      status: "Status",
      cost: "Narxi",
      object: "Bino",
      date: "Sana",
    },

    status: {
      waiting: "To'lovda",
      booked: "Band qilingan",
      sold: "To'lovda",
      closed: "Yopilgan",
      contract: "Sotilgan",
      cancelled: "Bekor qilingan",
    },

    view: {
      regenerate_warning:
        "Shartnoma faqatgina bir marta o'zgartirilishi mumkin , Davom etishdan oldin barcha malumotlarni tekshiring",
      regenerate_select_reason:
        "Shartnomaning o'zgartirilishi sababini tanlang",
      unfamiliar: "Notanish", // asd
      familiar: "Tanish", // asd
      edit_type: "Mijoz turini o'zgartirish", // asd
      save_btn: "Saqlash", // asd
      tariff: "Tarif", // asd
      dollar_rate: "Dollar kursi", // asd
      initial_fee: "Boshlang'ich to'lov", // asd
      apartment_price: "Xonadon narxi", // asd
      remainder: "Qoldiq", // asd
      add_payment: "To'lov qo'shish", // asd
      schedule: "Jadval", // asd
      sum: "Summa", // asd
      cancel_btn: "Bekor qilish", // asd
      type: "Turi", // asd
      comment: "Izoh", // asd
      payment_type: "To'lov turi", // asd
      payment_date: "To'lov sanasi", // asd
      payment_sum: "To'lov summasi", // asd
      paid: "To'langan summa", // asd
      comments: "Izohlar", // asd
      no_comment: "Hozircha izohlar yo'q", // asd
      add_comment: "Izoh qo'shish", // asd
      download_contract: "Shartnomani yuklab olish", // asd
      update_contract: "Shartnomani o'zgartirish", // asd
      cancel_contract: "Shartnomani bekor qilish", // asd
      payment_by_cash: "Naqd pul", // asd
      payment_transaction: "Pul ko'chirish orqali", // asd
      payment_other: "Boshqa to'lov turi", // asd,
      initial_payment: "Birinchi to'lov", // asd
      actions: "Amallar",
    },
  },

  roles: {
    title: "Rollar",
    name: "Nomi",
    users: "Foydalanuvchilar",

    permissions: {
      name_uz: "Rolning nomi (UZ)",
      name_ru: "Название роли (RU)",

      placeholder_uz: "O'zbek tilida",
      placeholder_ru: "Rus tilida",

      slug: "Guruh nomi prefiksi",

      objects: {
        view: "Obyektlarni ko'rishga ruxsat berish",
        create: "Obyekt yaratishga ruxsat berish",
        update: "Obyektlarni taxrirlashga ruxsat berish",
        delete: "Obyektlarni o'chirishga ruxsat berish",
        apartments: "Xonadonlar ro'yxatini ko'rishga ruxsat berish",
      },

      users: {
        view: "Foydalanuvchilarni ko'rishga ruxsat berish",
        create: "Foydalanuvchilar kiritishga ruxsat berish",
        update: "Foydalanuvchilarni taxrirlashga ruxsat berish",
        delete: "Foydalanuvchilarni o'chirishga ruxsat berish",
      },

      roles: {
        view: "Rollarni ko'rishga ruxsat berish",
        create: "Rollarni yaratishga ruxsat berish",
        update: "Rollarni taxrirlashga ruxsat berish",
        delete: "Rollarni o'chirishga ruxsat berish",
      },

      contracts: {
        view: "Shartnomalarni ko'rishga ruxsat berish",
        all: "Barcha shartnomalar uchun ruxsat berish",
        friends: "Tanish mijozlarni belgilashga ruxsat berish",
        cancelled: "Shartnomalarni bekor qilishga ruxsat berish",
        paid: "To'lovlarni qabul qilishga ruxsat berish",
        other_price: "Boshqa narx bilan sotishga ruxsat berish",
        change_date: "Shartnoma tuzilgan vaqtini o'zgartirishga ruxsat berish",
        monthly: "Oylik to'lovlarni o'zgartirishga ruxsat berish",
      },

      clients: {
        view: "Mijozlar ma'lumotlarini ko'rishga ruxsat berish",
        cancel_contract:
          "Band qilingan xonadonlarni bandlikdan chiqarishga ruxsat berish",
        delete: "Mijozlarni o'chirishga ruxsat berish",
        terminate: "Shartnomalarni bekor qilishga ruxsat berish",
        cancelled: "Bekor qilingan shartnomalarni ko'rishga ruxsat berish",
      },

      type_plan: {
        view: "Xonadonlar rejalarini ko'rishga ruxsat berish",
        update: "Xonadonlar rejalarini taxrirlashga ruxsat berish",
      },

      apartments: {
        view: "Xonadonlar haqida ma'lumotlarni ko'rishga ruxsat berish",
        reserve: "Xonadonlarni band qilishga ruxsat berish",
        contract: "Shartnoma tuzishga ruxsat berish",
        filter: "Xonadonlarni saralashga ruxsat berish (Filter)",
        edit: "Xonadonlarni taxrirlashga ruxsat berish (Xonadon raqami, xonalar soni, maydoni va hk.)",
        root_contract: "Shartnomalar uchun to'liq ruhsat berish",
      },

      debtors: {
        first_payment: "Birinchi to'lovlarni qabul qilishga ruxsat berish",
        first_payment_edit: "Birinchi to'lovni taxrir qilishga ruxsat berish",
        monthly_payment: "Oylik to'lovlarni qabul qilishga ruxsat berish",
        monthly_payment_edit: "Oylik to'lovlarni taxrir qilishga ruxsat berish",
      },

      // 'companies': {
      view: "Kompaniyalarni larni ko'rishga ruxsat berish",
      create: "Kompaniyalarni yaratishga ruxsat berish",
      update: "Kompaniyalarni taxrirlashga ruxsat berish",
      delete: "Kompaniyalarni o'chirishga ruxsat berish", // },
    },

    admin: "Administrator",
    manager: "Menedjer",
    accountant: "Xisobchi",
    executor: "Ijro etuvchi",
    sales_department: "Savdo rahbariyati",
    security_manager: "Xavfsizlik boshlig'i",
  },

  companies: {
    title: "Kompaniyalar",
    name: "Kompaniya",
    director: "Kompaniya direktori",
    type: "Kompaniya turi",
    number: "Raqam",
    inn: "STIR",
    mfo: "MFO",
    phone: "Telefon raqami",
    other_phone: "Qo'shimcha telefon raqami",
    payment_account: "Xisob raqami",
    bank_name: "Bank nomi",
    first_name: "Direktorning ismi",
    last_name: "Direktorning familiyasi",
    second_name: "Direktorning sharifi",
    accounts_number: "Hisoblar soni",
    type_enter: "Kompaniya turi",
    branch_enter: "Kompaniyani tanlang",
    address: "Kompaniya manzili",
    code: "To'lovchining ro'yxatga olish kodi",
    oked: "Oked",
    addPayment: "Hisob qo'shish",
    bank_name_uz: "Bank nomi (Узб.)",
    bank_name_ru: "Bank nomi (Рус.)",
    check_account: "Hisob raqami",
    more: "Kompaniya tafsilotlari",
    actions: "Amallar",
    add_company: "Kompaniya qo'shish",
    add_title: "Sarlavha qo'shish",
    search: "Kompaniya nomi",
    active_payment: "Aktiv hisob",
    inactive_payment: "Aktiv bo'lmagan hisob",
    delete_company: "Kompaniyani o'chirish",
    fax_number: "Telefon raqam / faks",
    counted_payment: "Hisoblar",
  },

  apartments: {
    title: "Xonadonlar",
    clone_tariff: "Tarifdan nusxa olish",
    delete_tariff: "Tarifni o'chirish",
    add_tariff: "Tarif qo'shish",
    first_payment: "Boshlang'ich to'lov",

    status: {
      cancelled: "Bekor qilingan",
      waiting: "Kutish",
      contract: "Shartnomada",
      sold: "Sotilgan",
      booked: "Band qilingan",
      closed: "Yopilgan",
      available: "Sotuvda",
      unavailable: "Sotilmaydi",
      hold: "Band qilinmoqda",
      not_available: "Sotuvda emas",
      decorated: "Bezatilgan",
    },

    list: {
      choose: "Tanlash",
      reset: "Qayta o'rnatish",
      contract_all: "Barchasini tuzish",
      number: "Uy raqami",
      object: "Obyekt",
      rooms: "Xonalar soni",
      floor: "Qavat",
      area: "Maydoni",
      status: "Statusi",
      price: "Narxi",
      building: "Bino",
      block: "Korpus",
      book: "Band qilish",
      confirm: "Shartnoma tuzish",
      filter: "Filtr",
      apartments: "Xonadonlar",
      contract: "Shartnoma",
      entrance: "Kirish qismi",
      balcony: "Balkon",
      period_date: "Band qilish muddati",
      more: "To'liq ma'lumot",
      cancel_contract: "Shartnomani bekor qilish",
      download_contract: "Shartnomani yuklab olish",
      view_client: "Mijoz ma'lumotlarini ko'rish",
      cancel_reserve: "Xonadonni bandlikdan chiqarish",
      view_manager: "Menedjer haqida ma'lumot",
      price_sold: "Sotilgan narxi",
    },

    filter: {
      apartments: "Xonalar soni",
      floor: "Qavat",
      ot: "dan",
      do: "gacha",
      price: "Narx bo'yicha",
      area: "Maydon bo'yicha",
      object: "Obyekt",
      status: "Status bo'yicha",
      free: "Faqat mavjudlari",
      usd: "Narx $ da",
      clear: "Qayta o'rnatish ",
      blocks: "Binolar",
      contract_number: "Shartnoma raqami", // asd
      apartment_number: "Xonadon raqami", // asd
      filter_btn: "Filtrlash", // asd
    },

    view: {
      plan: "Xonadon rejasi",
      number: "Xonadon raqami",
      area: "Maydon",
      rooms: "Xonalar soni",
      floor: "Qavat",
      price_m2: "1м2 narxi",
      total_price: "Sotuvdagi narxi",
      status: "Status",
      variant: "To'lov varianti",
      other_variant: "Boshqa variant",
      enter_discount: "To'lov turini tanlang:", // agree.placeholder
      number_of_blocks: "Blok qavatlari soni", // asd
      object: "Ob'ekt", // asd
      address: "Manzil", // asd
      completion_date: "Qurib bitkazish sanasi", // asd
      price_for_m2: "1м2 narxi:", // asd
      discount_per_m2: "m2 uchun chegirma:", // asd
      discount_all: "Umumiy chegirma",
      discount_price: "Umumiy summadan chegirma",
      prepayment: "Oldindan to'lov", // asd
      total: "Jami", // asd
      print: "Chop etish", // asd
    },

    agree: {
      number: "Shartnoma raqami",
      date_contract: "Shartnoma sanasi", // (месяц/число/год)
      fio: "FISH",
      first_name: "Ismi",
      last_name: "Familiyasi",
      second_name: "Sharifi",
      passport_series: "Pasport raqami",
      issued_by_whom: "Pasport berilgan joy",
      language: "Til",
      phone: "Telefon raqami",
      type_client: "Mijoz turi",
      other_phone: "Qo'shimcha telefon raqami",
      date_of_issue: "Pasport berilgan sana",
      birth_day: "Tug'ilgan sana", //(месяц/число/год)
      discounts: "Xarid turi",
      edit_price: "Narxni taxrirlash",
      edit_prices: "Narxni taxrirlash",
      first_payment_date: "Birinchi to'lov sanasi",
      payment_date: "Birinchi oylik to'lov sanasi",
      placeholder: {
        number: "D-6",
        date_contract: "20.12.2020",
        search: "Telefon raqami yoki pasport raqami orqali topish",

        first_name: "masalan: Ахмад",
        last_name: "masalan: Жасуров",
        second_name: "masalan: Хакимович",

        first_name_lotin: "masalan: Axmad",
        last_name_lotin: "masalan: Jasurov",
        second_name_lotin: "masalan: Khakimovich",

        passport_series: "masalan: AA1234567",
        issued_by_whom: "masalan: Тошкент шахар Олмазор тумани И.И.Б",
        phone: "masalan: +99899 123 45 67",
        other_phone: "masalan: +99899 765 43 21",
        enter_discount: "To'lov turini tanlang",
      },
    },
    tab_names: {
      all: "Hammasi", //asd
      available: "Mavjud", //asd
      booked: "Band qilingan", //asd
      sold: "Sotilgan", //asd
      unavailable: "Mavjud emas", //asd
      promo: "Aksiya",
    },
  },

  type_plan: {
    title: "Xonadon rejalari",
    image: "Ko'rinishi",
    connect_plan: "Bog'langan reja",
    name: "Nomlanishi",
    area: "Maydoni",
    balcony: "Balkon",
    no_img: "(xonadon rejasi mavjud emas)",
    yes_img: "(xonadon rejasi mavjud)",
    plan: "Xonadon rejasi",
    floor: "Qavat",
    plans: "Barcha xonadon rejalari",
    list: "Xonadon rejalari ro'yxati",
    plan_required: "Xonadon loyihasini tanlashingiz shart",
    delete_title:
      "Bu xonadon rejasida {apartments_count} ta xonadon mavjud boshqa rejaga o'tkazishingiz kerak",
  },

  debtors: {
    new_title: "Qarzdorlar",
    title: "Qarzdorlar",
    overdue: "Muddati o'tgan", // asd
    familiars: "Tanishlar", // asd
    contract_number: "№ shartnoma", // asd
    client_name: "mijoz ismi", // asd
    client_number: "mijoz raqami", // asd
    client_type: "mijoz turi", // asd
    import_debtors: "Qarzlar importi",
    import_text:
      "Qarzlarni import qilish uchun faylni .xls yoki .csv formatida yuklang",
    file_field: "Fayl maydoni",
    property: "Xususiyat",
    file: "Fayldagi maydon",
    contract_file: "Fayldagi shartnoma",
    contract_system: "Tizimdagi shartnoma",
    alias_system: "Tizimdagi shartnoma taxallusi",
    import_leave: "Ma'lumotlarni yoqotishingiz mumkin. Chiqishni xoxlaysizmi?",
  },

  backup: {
    title: "Zaxira",
  },

  object: {
    home: "UY",
    entrance: "PODEYZ",
    level: "Qavat",
    number_level: "QAVATLAR SONI",
    flat: "XONADON",
    number_flat: "XONALAR SONI",
    area: "MAYDONI",
    balcony: "BALKON",
    complete: "QURILISHNI TUGASHI",
    sort: {
      flat: "Xonalar",
      area: "Yashash maydoni",
      block: "Blok",
      building: "Binolar",
      number_flat: "Kvartira raqami",
      number_parking: "Avtoturargoh raqami",
      number_rooms: "Xonalar soni",
      apply: "Qo'llash",
      clear: "Tozalash",
    },
    chess: "Shaxmatka",
    list: "Ro'yxat",
    parking: "Avtoturargoh",
    building: "Bino",
    price: "Narx",
    plan: "Rejalar",
    flats: "Xonadonlar",
    rooms: "xonalar",
    status: {
      available: "Sotuvda",
      sold: "Sotilgan",
      booked: "Band qilingan",
      contract: "Rasmiylashtirilgan",
      disable: "Sotilmaydi",
      hold: "Band qilinmoqda",
    },
  },

  objects: {
    archived: "Arxivlangan",
    active: "Aktiv",
    title: "Obyektlar",
    apartments: "Xonadonlar",
    address: "Manzil",
    full_address: "Shartnoma sanasi",
    build_date: "Qurib bitkazish sanasi",
    credit_month: "Bo'lib to'lashga beriladigan oylar soni",
    deal_file: "Shartnoma fayli",
    select_category: "Turini tanlash",
    sale: "Sotuv",
    booking: "Band qilish",
    contract: "Rasmiylashtirish",
    make_it_main_contract: "Asosiy shartnoma qilib belgilash",
    unfinished_contract: "Tugallanmagan shartnomalar",
    view_apartments: "xonadonlar",
    view_level: "qavatlar",
    view_parkings: "avtoturargohlar",
    showM2Price: "M2 narxini kursatish",
    hasParking: "Avtoturargoh",

    deal_template: {
      name: "Shartoma andozasi",
      type: "Turi",
      main: "Asosiy",
      title: "Shartnoma shablonlari ro'yxati",
      warning_message:
        "Siz barcha asosiy shartnomalarni tanlamadingiz! Iltimos, barcha asosiy shartnomalarni tanlang!",
    },

    create: {
      name: "Nomi",
      blocks_apartment: "XONADON BLOKLARI",
      copy_block: "BLOKNI KO'PAYTIRISH",
      copy_building: "BINONI KO'PAYTIRISH",
      balcony_price: "Balkon uchun narx",

      new: "Yangi obyekt yaratish",
      type_plan: "Xonadon rejalari turlari",
      no_type_plan: "xonadon rejalari mavjud emas",
      new_type_plan: "Xonadon rejasini yaratish",
      enter_press: "Tanlash uchun Enter ni bosing",
      entrance: "Kirish joyi",
      other_price: "m2 uchun boshqa narx",
      price_m2: "m2 uchun narx",
      price_update: "Narxni o'zgartirish",
      clone_apartments: "Qavatni ko'paytirish",
      clone: "Ko'paytirish",
      choose_clone: "Qaysi qavatdan ko'chirilsin?",
      parking: {
        add: "Добавить парковку",
      },
      plan: {
        name: "Xonadon rejasi",
        area: "Maydon",
        balcony: "Balkoni bormi?",
        balcony_area: "Balkon maydoni",
        balcony_paid: "Balkon pullimi?",
        add: "Xonadon rejasini qo'shish",
        search: "Xonadon rejasi nomi",
        add_image: "Rasm qo'shish",
      },
      fast_plan: {
        name: "Joylashtirish chizmalari",
        add: "Chizma rejasini qo'shing",
        choose_apartments: "Tegishli kvartirani tanlang",
        add_plan: "Chizma qo'shing",
        select_room: "Kvartirani tanlang",
        search: "Fayl nomi yoki tegishli reja",
        import_text: "Tuzilish chizmasi uchun .pdf yoki .png faylni yuklang",
      },

      apartments: "Xonadonlar",
      floors: "Qavatlar",

      blocks: "Bloklar",
      buildings: "Binolar",
      new_block: "Yangi blok",
      edit_block: "Blokni taxrirlash",
      count_floors: "Qavatlar soni",
      count_apartments: "Xonadonlar soni",

      price_area: "m2 uchun narx",
      new_price: "Yangi narx",
      create_price: "Narx qo'shish",
      alert_price: "Davom etish uchun qavatlar narxini qo'shing!",
      prices: "Narxlar",

      apartment: "Xonadon",
      floor: "Qavat",
      type_plan_apartment: "Xonadon rejasi turi",
      rooms: "Xonalar soni",
      area: "Maydoni",
      price: "Narxi",

      pre_pay: "Oldindan to'lov",
      discount: "Chegirma summasi",

      new_rules_discount: "Yangi chegirma qoidalari",

      prepay_from: "dan",
      prepay_to: "gacha",

      create_build: "Bino qo'shish",
      create_discount: "Chegirmani qo'shish",
      discounts: "Sotuv tarifi",

      choose_plan: "Xonadon rejasini tanlang",

      tariff: {
        main: "Asosiy",
        type: "Tarif turi",
        type_name: "Tarif nomi",
        price: "Narx",
        price_balcony: "Balkonli narx",
        add_price: "Narx qo'shish",
        floor_placeholder: "Qavatlar yoki spetsifikatsiyalar",
        add_percent: "Qo'shiladigan foiz",
        fixed: "Belgilangan",
        percent: "Foizli",
        addition: "Bo'lingan",
      },
    },

    placeholder: {
      name: "Obyekt nomi",
      is_sold: "Доступность к продаже",

      address: "Obyekt manzili",
      full_address: "Shartnoma uchun manzil",

      type_plan: "Xonadon rejasi nomi",

      block_name: "Blok nomi",

      build_date: "20.12.2020",
      building_name: "Korpus nomi",
      credit_month: "Oylik to'lov uchun oylar soni",
    },
  },

  building_layout: {
    add_layout: "Xonadon rejasini qo’shish",
    layout_name: "Xonadon rejasi nomi",
    add_title: "Sarlavha qo'shish",
    floors: "Qavat(lar)",
    upload: {
      add_image: "Rasm qo'shish",
      add_video: "Video qo'shish",
      add_file: "Fayl qo'shish",
      add_audio: "Audio qo'shish",
    },
  },

  release_note: {
    add_release_note: "Reliz yozuvlarini qo'shish",
    release_note: "Relizlar qaydlari",
    version_number: "Versiya raqami",
    texts: "Matnlar",
    new: "Yangi",
    published: "Nashr qilingan",
    comment:
      "Siz har doim versiya raqamini bosish orqali sozlamalarda nashr yozuvlarini topishingiz mumkin.",
  },

  sweetAlert: {
    want_archive: "Arhivlashni hohlaysizmi?",
    want_unarchive: "Arhivdan olishni hohlaysizmi?",
    want_delete: "Вы действительно хотите удалить?",
    want_undelete: "Вы действительно хотите восстановить?",
    archived: "Arhivlandi!",
    unarchived: "Arhivdan olindi!",
    add_image: "Rasm qushing!",
    choose_building: "Bino Tanlang!",
    error: "Hatolik!!!",

    title: "Bu ishni amalga oshirishga aminmisiz?",
    text: "O'chirilsinmi?",
    text_copy_block: "Qavatni ko'chirishni hohlaysizmi?",
    yes: "Xa, o'chirilsin",
    yes_close: "Xa, yopilsin",
    yes_agree: "Xa, amalga oshirilsin",
    yes_clone: "Xa, ko'paytirilsin",
    yesPure: "Xa",
    no: "",
    deleted: "O'chirildi!",
    text_cancel_contract: "Shartnomani rostdan bekor qilmoqchimisiz?",
    yes_cancel_contract: "Xa, bekor qilish",
    canceled_contract: "Shartnoma bekor qilindi",
    payment_success: "To'lov muvaffaqiyatli qabul qilindi",
    payment_success_added: "To'lov muvaffaqiyatli qo'shildi",
    payment_list_add: "To'lovlar ro'yxatiga muvaffaqiyatli qo'shildi",

    successfully_edited: "Muvaffaqiyatli tahrirlandi",
    success_create_payment: "Hisob raqam qo'shildi",
    success_update_payment: "Hisob raqam yangilandi",
    text_cancel_agree: "Rostdan ham bekor qilishni xoxlaysizmi?",
    text_agree: "Rostan ham shartnoma tuzishni hohlaysizmi?",

    text_cancel_reserve: "Rostdan ham band qilishni bekor qilmoqchimisiz?",
    yes_cancel_reserve: "Xa, bekor qilish",
    canceled_reserve: "Bandlik bekor qilindi",
    success_create_role: "Rol muvaffaqiyatli yaratildi",
    success_create_comment: "Izoh muvaffaqiyatli yaratildi",
    success_update_role: "Rol muvaffaqiyatli taxrirlandi",
    success_update_company: "Kompaniya muvaffaqiyatli o'zgartirildi",
    success_delete_company: "Kompaniya muvaffaqiyatli o'chirildi",
    are_you_sure_delete_role:
      "Agar rolni o'chirmoqchi bo'lsangiz bu ro'ldagi foydalanuvchilar uchun yangi rol tanlashingiz kerak!", // 'text_cancel_contract': 'Вы действительно хотите расторгнуть контракт',
    payment_edit: "To'lovni taxrirlash",
    debtors: {
      payment_info: "To'lov summasi!",
      payment_edit: "To'lovni taxrirlash",
      payment_delete: "To'lovni rostdan xam bekor qilmoqchimisiz?",
      next: "Davom etish",
      are_you_sure:
        "Siz chindan ham to'lovni tasdiqlamoqchimisiz? Keyin bu amaliyotni bekor qilib bo'lmaydi!",
      yes: "Xa, to'lvoni tasdiqlayman",
      placeholder: "Agar to'lovga izohingiz bo'lsa yozing!",
    },
  },

  clients: {
    title: "Mijozlar bazasi",
    fio: "MIJOZ ISMI",
    phone: "Telefon raqami",
    amount: "NARX",
    apartment: "XONADON",
    object: "OBYEKT",
    file: "Shartnoma FAYLI",
    number: "Shartnoma raqami",
    language: "Til",
    date: "Sana",
    // search: "Shartnoma raqami yoki mijozning telefon raqami orqali qidirish",
    search: "Shartnoma raqami orqali qidirish",
  },

  users: {
    title: "Foydalanuvchilar",
    name: "Ism",
    phone: "Telefon raqami",
    roles: "Rol",
    object: "Obyekt",
    branch: "Filial",
    login: "Login",
    placeholder: "FIO, telefon raqami, email",
  },

  accountants: {
    title: "Xisob",
    role_title: "Xisob bo'limi",
  },

  cashier: {
    title: "G'aznachilar",
  },

  settings: {
    title: "Sozlamalar",
  },

  branches: {
    title: "Filiallar",
    name: "Filial",
    branch_name: "Filial nomi",
    branch_address: "Filial manzili",
    branch_phone: "Filial telefon raqami",
    branch_manager: "Filial menedjeri",
    manager_first_name: "Menedjerning ismi",
    manager_last_name: "Menedjerning familiyasi",
    manager_second_name: "Menedjerning sharifi",
    manager_phone_number: "Menedjerning telefon raqami",
    manager_email: "Menedjerning elektron pochtasi",
    manager_avatar: "Menedjerning profil surati",
    date_contract: "Shartnoma sanasi",
    number_contract: "Shartnoma raqami",
    date_implementation: "Buyurtma sanasi",
    number_implementation: "Buyurtma raqami",
  },

  filter_names: {
    all: "Barchasi",
    booked: "Band qilingan",
    sold: "Sotilgan",
    in_payment: "To'lovda",
    archive: "Arxiv",
  },

  contract_compare: {
    compare_title: "Taqqoslash uchun ma'lumotlar",
    course_usd: "Dollar kursi sotilganda",
    tariff: "Sotish tarifi",
    first_price: "Boshlang'ich umumiy narx",
    full_price: "Umumiy sotish narxi",
    discount: "Umumiy chegirma",
    first_price_m2: `M<sup>2</sup> uchun boshlang'ich narx`,
    last_price_m2: `Sotishdagi M<sup>2</sup> uchun narx`,
    discount_m2: `m<sup>2</sup> uchun chegirma`,
  },
};
