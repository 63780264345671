var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-sidebar',{attrs:{"right":"","shadow":"","backdrop":"","no-header":"","sidebar-class":"sidebar__apartment","body-class":"sidebar__apartment-body","aria-labelledby":"sidebar-no-header-title","id":"apartment-express-view","backdrop-variant":_vm.variant},scopedSlots:_vm._u([{key:"default",fn:function({}){return [(_vm.hasApartment && !_vm.appLoading)?_c('section',[_c('div',{staticClass:"head d-flex justify-content-between align-items-center pdf-item"},[_c('span',{staticClass:"d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"close__button d-flex justify-content-center align-items-center",on:{"click":_vm.hideApartmentSidebar}},[_c('base-arrow-left-icon',{attrs:{"width":32,"height":32}})],1),_c('span',{staticClass:"section__title"},[_vm._v(" "+_vm._s(_vm.sidebarApartment.object.name)+" , "+_vm._s(_vm.sidebarApartment.block.name)+" ")])]),(_vm.holderViewPms && _vm.isNUNEZ(_vm.sidebarApartment.holder))?_c('span',{staticClass:"ml-2 mr-2 cursor-pointer"},[_c('x-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"light-blue-500",attrs:{"size":"32","name":"person","title":_vm.holderTooltipTitle(_vm.sidebarApartment.holder)}})],1):_vm._e(),_c('hide-price-button')],1),_c('div',{staticClass:"d-flex w-100 justify-content-end"},[_c('div',{staticClass:"d-flex",staticStyle:{"font-size":"12px"}},[(_vm.statusViewPms && _vm.isNUNEZ(_vm.sidebarApartment.status))?_c('span',{staticStyle:{"display":"inline-flex","height":"40px","padding":"0 20px","justify-content":"center","align-items":"center","gap":"6px","flex-shrink":"0","color":"white","border-radius":"28px","white-space":"nowrap"},style:({
              'background-color': _vm.sidebarApartment.status.color,
              color:
                _vm.sidebarApartment.status.color === '#ffffff'
                  ? '#000000'
                  : '#ffffff',
              'border-radius':
                _vm.statusViewPms && _vm.isNUNEZ(_vm.sidebarApartment.status)
                  ? '28px 0 0 28px'
                  : '28px',
            })},[_vm._v(" "+_vm._s(_vm.sidebarApartment.status.title[_vm.$i18n.locale])+" ")]):_vm._e(),_c('span',{staticClass:"apartment__status d-flex justify-content-center align-items-center",class:`status-${_vm.status}`,staticStyle:{"display":"inline-flex","height":"40px","padding":"0 20px","justify-content":"center","align-items":"center","gap":"6px","flex-shrink":"0","border-radius":"28px","white-space":"nowrap"},style:({
              'border-radius':
                _vm.statusViewPms && _vm.isNUNEZ(_vm.sidebarApartment.status)
                  ? '0 28px 28px 0'
                  : '28px',
            })},[_vm._v(" "+_vm._s(_vm.$t(`apartments.status.${_vm.status}`))+" ")])])]),_c('primary-information',{staticClass:"pdf-item",attrs:{"apartment":_vm.sidebarApartment},on:{"for-print":_vm.getCalc}}),(_vm.apartmentCommentsPermission.view)?_c('apartment-comments',{attrs:{"comments-data":_vm.commentsData,"comment-loading":_vm.commentLoading,"express-view":true,"permissions":_vm.apartmentCommentsPermission,"apartment-uuid":_vm.apartmentUuid},on:{"update-comments":_vm.getComments}}):_vm._e(),_c('div',{staticClass:"action-block"},[_c('router-link',{attrs:{"to":{
            name: 'apartment-view',
            params: {
              object: _vm.sidebarApartment.object.id,
              id: _vm.apartment.uuid,
            },
          }}},[_c('base-button',{staticClass:"violet-gradient",attrs:{"id":"learnMore","text":`${_vm.$t('more_info')}`},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('base-eye-icon',{attrs:{"square":20,"fill":"#fff"}})]},proxy:true}],null,true)})],1),_c('b-tooltip',{attrs:{"target":"learnMore","triggers":"hover"}},[_c('p',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.$t("more_info"))+" ")])]),(_vm.permission.order)?_c('base-button',{attrs:{"text":`${_vm.$t('apartments.list.confirm')}`,"design":"violet-gradient"},on:{"click":_vm.orderApartment}}):_vm._e(),(_vm.permission.continueOrder)?_c('base-button',{staticClass:"checkout__button violet-gradient",attrs:{"text":`${_vm.$t('continue_registration')}`},on:{"click":_vm.continueApartmentOrder}}):_vm._e(),(_vm.permission.reserve)?_c('base-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-reserve-create",modifiers:{"modal-reserve-create":true}}],attrs:{"text":`${_vm.$t('apartments.list.book')}`},on:{"click":function($event){_vm.showReservationModal = true}},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('base-minus-circle-icon',{attrs:{"square":20,"fill":"#4B5563"}})]},proxy:true}],null,true)}):_vm._e(),(_vm.permission.cancelReserve)?_c('base-button',{staticClass:"reserve__button",attrs:{"text":`${_vm.$t('apartments.list.cancel_reserve')}`},on:{"click":_vm.cancelReservation}}):_vm._e(),(_vm.permission.contract)?_c('router-link',{attrs:{"to":{
            name: 'contracts-view',
            params: { id: _vm.sidebarApartment.order.id },
          }}},[_c('base-button',{attrs:{"design":"violet-gradient px-5","text":`${_vm.$t('apartments.list.contract')}`}})],1):_vm._e(),_c('button',{staticClass:"print__button bg-gray-100 d-flex justify-content-center align-items-center",attrs:{"disabled":_vm.isDownloading,"id":"print"},on:{"click":_vm.printApartmentInformation}},[(_vm.isDownloading)?_c('base-loading',{attrs:{"height":40}}):_c('base-print-icon',{attrs:{"square":20,"fill":"#4B5563"}})],1),_c('b-tooltip',{attrs:{"target":"print","triggers":"hover"}},[_c('p',{staticClass:"tooltip-text"},[_vm._v(" "+_vm._s(_vm.$t("apartments.view.print"))+" ")])])],1)],1):_vm._e(),(_vm.visible && Object.keys(_vm.sidebarApartment).length)?_c('PdfTemplate',{ref:"html2Pdf",attrs:{"apartment":_vm.sidebarApartment,"print-calc":_vm.printCalc,"imgData":_vm.imgDataUrl},on:{"has-downloaded":_vm.completePrintingProcess}}):_vm._e(),(_vm.appLoading)?_c('base-loading',{staticClass:"h-100"}):_vm._e()]}}]),model:{value:(_vm.visibleComp),callback:function ($$v) {_vm.visibleComp=$$v},expression:"visibleComp"}})
}
var staticRenderFns = []

export { render, staticRenderFns }